import LocalFont from 'next/font/local';

const nHaasGroteskTxProFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/NHaasGroteskTxPro.woff2',
      weight: 'bold',
      style: 'normal',
    },
  ],
});

const neueAachenProRegularFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/NeueAachenProRegular.woff2',
      weight: 'normal',
      style: 'normal',
    },
  ],
});

const nHaasGroteskTxProFontRegular = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/NhassGroteskProRegular.woff2',
      weight: 'normal',
      style: 'normal',
    },
  ],
});

export { nHaasGroteskTxProFont, neueAachenProRegularFont, nHaasGroteskTxProFontRegular };
